import { RootState } from 'src/store';

import { IP2pRuTransactionsTableData } from './types';

export const isLoading = (state: RootState): boolean => state.p2pRuTransactions.isLoading;

export const isSending = (state: RootState): boolean => state.p2pRuTransactions.isSending;

export const table = (state: RootState): IP2pRuTransactionsTableData => state.p2pRuTransactions.data;

export const ruTransactionsSelectors = {
  isLoading,
  isSending,
  table,
};
