import { NotificationManager } from 'react-notifications';
import { AxiosError } from 'axios';
import qs from 'query-string';
import { DEFAULT_PARAMS } from 'src/constants';
import { AppDispatch } from 'src/store';
import { IQueryParams } from 'src/types';
import { removeEmpty } from 'src/utils/removeEmpty';
import { createAction } from '@reduxjs/toolkit';

import i18n from '../../../i18n';
import { EBackErrorCode } from '../../../types/Error';
import { readBlobErrorData } from '../../../utils/errorReader';

import { ruTransactionsApi } from './requestHandlers';
import { EP2pRuTransactionsActionsTypes, IFetchP2pRuTransactions, IP2pRuTransactionsTableData } from './types';

const setLoading = createAction<boolean>(EP2pRuTransactionsActionsTypes.SetIsLoading);
const setSending = createAction<boolean>(EP2pRuTransactionsActionsTypes.SetIsSending);
const setTransactionsTable = createAction<IP2pRuTransactionsTableData>(EP2pRuTransactionsActionsTypes.SetTable);

const fetchTransactions = ({ history, params = {}, isReset }: IFetchP2pRuTransactions) => {
  return async (dispatch: AppDispatch): Promise<void> => {
    dispatch(setLoading(true));

    const initialParams = qs.parse(history.location.search);

    const mergedParams = isReset ? { ...DEFAULT_PARAMS } : { ...DEFAULT_PARAMS, ...removeEmpty({ ...initialParams, ...params }) };

    const transactions = await ruTransactionsApi.fetchTransactionsRequest({ params: mergedParams });

    dispatch(setTransactionsTable(transactions));

    history.replace({
      search: qs.stringify({ offset: mergedParams.offset, limit: mergedParams.limit }, { skipNull: true }),
    });

    dispatch(setLoading(false));
  };
};

const declineDispute = (uuid: string) => {
  return async (dispatch: AppDispatch): Promise<void> => {
    dispatch(setSending(true));

    try {
      await ruTransactionsApi.declineDispute(uuid);
      NotificationManager.success('Transaction was declined');
    } catch (e) {
      NotificationManager.error("Can't decline transaction");
      throw e;
    } finally {
      dispatch(setSending(false));
    }
  };
};

const completeDispute = (uuid: string, amountToEnroll: number) => {
  return async (dispatch: AppDispatch): Promise<void> => {
    dispatch(setSending(true));

    try {
      await ruTransactionsApi.completeDispute(uuid, amountToEnroll);
      NotificationManager.success('Transaction was completed');
    } catch (e) {
      NotificationManager.error("Can't complete transaction");
      throw e;
    } finally {
      dispatch(setSending(false));
    }
  };
};

const declineExpiration = (uuid: string) => {
  return async (dispatch: AppDispatch): Promise<void> => {
    dispatch(setSending(true));

    try {
      await ruTransactionsApi.declineExpiration(uuid);
      NotificationManager.success('Transaction was declined');
    } catch (e) {
      NotificationManager.error("Can't decline transaction");
      throw e;
    } finally {
      dispatch(setSending(false));
    }
  };
};

const completeExpiration = (uuid: string, amountToEnroll: number) => {
  return async (dispatch: AppDispatch): Promise<void> => {
    dispatch(setSending(true));

    try {
      await ruTransactionsApi.completeExpiration(uuid, amountToEnroll);
      NotificationManager.success('Transaction was completed');
    } catch (e) {
      NotificationManager.error("Can't complete transaction");
      throw e;
    } finally {
      dispatch(setSending(false));
    }
  };
};

export const getTransactionScreens = (uuid: string) => {
  return async (): Promise<string[]> => {
    try {
      return await ruTransactionsApi.getScreens(uuid);
    } catch (e) {
      NotificationManager.error("Can't fetch a payment screens");
      throw e;
    }
  };
};

export const getDownloadCsvFile = (params: IQueryParams) => {
  return async (): Promise<string> => {
    setSending(true);
    try {
      return await ruTransactionsApi.getDownloadCsvHref(params);
    } catch (e) {
      const error = e as AxiosError<Blob>;
      const errorData = await readBlobErrorData(error);
      if (errorData.code === EBackErrorCode.REPORT_PERIOD_TOO_LONG) {
        NotificationManager.error(i18n.t('ruTransactions.csvDownloadValidationError'));
      }
      throw e;
    } finally {
      setSending(false);
    }
  };
};

export const p2pRuTransactionsActions = {
  fetchTransactions,
  setSending,
  setLoading,
  setTransactionsTable,
  declineDispute,
  completeDispute,
  declineExpiration,
  completeExpiration,
  getTransactionScreens,
  getDownloadCsvFile,
};
