import auth from 'src/store/api/auth';
import p2pRuTransactions from 'src/store/api/p2pRuTransactions';
import paymentAccounts from 'src/store/api/paymentAccounts';
import paymentMethods from 'src/store/api/paymentMethods';
import settings from 'src/store/api/settings';
import transactions from 'src/store/api/transactions';
import ui from 'src/store/api/ui';
import { combineReducers } from '@reduxjs/toolkit';

const rootReducer = combineReducers({
  auth,
  ui,
  transactions,
  p2pRuTransactions,
  paymentMethods,
  paymentAccounts,
  settings,
});

export default rootReducer;
