import { createReducer } from '@reduxjs/toolkit';

import { setApplicationData, setLoading, setSending, setUserData } from './actions';
import { Application, User } from './types';

interface SettingsState {
  isLoading: boolean;
  isSending: boolean;
  users: User | null;
  applications: Application | null;
}

const initialState: SettingsState = {
  isLoading: false,
  isSending: false,
  users: null,
  applications: null,
};

const settingsReducer = createReducer(initialState, (builder) => {
  builder
    .addCase(setLoading, (state, action) => {
      state.isLoading = action.payload;
    })
    .addCase(setUserData, (state, action) => {
      state.users = action.payload;
    })
    .addCase(setApplicationData, (state, action) => {
      state.applications = action.payload;
    })
    .addCase(setSending, (state, action) => {
      state.isSending = action.payload;
    });
});

export default settingsReducer;
