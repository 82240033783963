import { createAction } from '@reduxjs/toolkit';

export const setLanguage = createAction<string>('ui/language/set');

export const toggleTheme = createAction('ui/theme/toggle');

export const showSidebar = createAction('ui/sidebar/show');

export const hideSidebar = createAction('ui/sidebar/hide');

export const toggleSidebar = createAction<boolean>('ui/sidebar/toggle');
