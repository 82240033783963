import { RootState } from 'src/store';
import { createSelector } from '@reduxjs/toolkit';

import { IPaymentMethod } from './types';

export const selectPaymentMethods = (state: RootState): IPaymentMethod[] => state.paymentMethods.data.items || [];

export const selectPaymentMethodsOptions = createSelector(selectPaymentMethods, (paymentMethods) => {
  if (paymentMethods.length) {
    return paymentMethods.map(({ method }) => {
      return { label: method, value: method };
    });
  }

  return [];
});

export const selectCurrenciesOptions = createSelector(selectPaymentMethods, (paymentMethods) => {
  if (paymentMethods.length) {
    return paymentMethods.map(({ currency }) => {
      return { label: currency, value: currency };
    });
  }

  return [];
});
