import { NotificationManager } from 'react-notifications';
import axios from 'axios';
import { API_KEY } from 'src/constants';
import { createApiSign } from 'src/utils/auth';

import { IFetchTransactionsRequest, IScreen, ITransactionsData, ITransactionStatusUpdate } from './types';

const TRANSACTIONS_URL = 'v1/backoffice/p2p/transactions';
const TRANSACTION_URL = 'v1/backoffice/p2p/transaction';

export const fetchTransactionsRequest = async ({ params, isRequestsPage }: IFetchTransactionsRequest): Promise<ITransactionsData> => {
  try {
    const res = await axios.post(`${TRANSACTIONS_URL}/${isRequestsPage ? 'cashout' : 'unpaid'}`, params);

    return res.data;
  } catch (error) {
    NotificationManager.error("Can't fetch transactions");

    return {
      items: [],
    };
  }
};

export const updateTransactionStatusRequest = async (data: ITransactionStatusUpdate): Promise<boolean> => {
  try {
    const headers = {
      'X-API-KEY': API_KEY,
      'X-API-SIGN': createApiSign(data.body),
    };

    const res = await axios.post(`${TRANSACTION_URL}/${data.id}/update`, data.body, {
      headers,
    });
    if (!res.data.success) {
      throw new SyntaxError('Error');
    }
    NotificationManager.success('Status successfully updated');

    return true;
  } catch (error) {
    NotificationManager.error("Can't update a transaction status");
    return false;
  }
};

export const getTransactionScreensRequest = async (id: number): Promise<IScreen[] | null> => {
  try {
    const res = await axios.get(`${TRANSACTION_URL}/${id}/paymentProofs`);

    return res.data;
  } catch (error) {
    NotificationManager.error("Can't fetch a payment proofs");
    return null;
  }
};
