import { NotificationManager } from 'react-notifications';
import axios from 'axios';
import omit from 'lodash/omit';

import { Application, User } from './types';

const CUSTOMER_PROPERTIES_URL = 'v1/backoffice/p2p/customer-properties';
const TRANSACTION_PROPERTIES_URL = 'v1/backoffice/p2p/transaction-properties';

export const fetchUserRequest = async (): Promise<User | null> => {
  try {
    const res = await axios.get(CUSTOMER_PROPERTIES_URL);

    return res.data;
  } catch (error) {
    NotificationManager.error("Can't fetch users settings");
    return null;
  }
};

export const updateUserRequest = async (data: User): Promise<User | null> => {
  try {
    const bodyRequest = omit(data, 'id');
    const res = await axios.put(`${CUSTOMER_PROPERTIES_URL}/${data.id}`, bodyRequest);

    NotificationManager.success('An users settings successfully updated');

    return res.data;
  } catch (error) {
    NotificationManager.error("Can't update an users settings");
    return null;
  }
};

export const fetchApplicationRequest = async (): Promise<Application | null> => {
  try {
    const res = await axios.get(TRANSACTION_PROPERTIES_URL);

    return res.data;
  } catch (error) {
    NotificationManager.error("Can't fetch applications settings");
    return null;
  }
};

export const updateApplicationRequest = async (data: Application): Promise<Application | null> => {
  try {
    const bodyRequest = omit(data, 'id');
    const res = await axios.put(`${TRANSACTION_PROPERTIES_URL}/${data.id}`, bodyRequest);

    NotificationManager.success('An applications settings successfully updated');

    return res.data;
  } catch (error) {
    NotificationManager.error("Can't update an applications settings");
    return null;
  }
};
