import axios from 'axios';

import { logoutUser } from './store/api/auth';
import store from './store';

const { dispatch } = store;

axios.defaults.baseURL = process.env.REACT_APP_API_HOST;
axios.defaults.headers.post['Content-Type'] = 'application/json';
axios.defaults.headers.post['Content-Type'] = 'application/json';
axios.defaults.headers.post.Accept = 'application/json, text/javascript, */*; q=0.01';
axios.defaults.paramsSerializer = { ...(axios.defaults.paramsSerializer || {}), indexes: null };

axios.interceptors.response.use(
  (response) => response,
  (error) => {
    const { status } = error.response;

    if (status === 401) {
      dispatch(logoutUser());
    }

    return Promise.reject(error);
  },
);
