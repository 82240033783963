import { THEME_MODE } from 'src/constants/localStorage';
import { createReducer } from '@reduxjs/toolkit';

import { hideSidebar, setLanguage, showSidebar, toggleSidebar, toggleTheme } from './actions';

interface IUiState {
  darkMode: boolean;
  language: string;
  sidebar: boolean;
}

const initialState: IUiState = {
  darkMode: !!localStorage.getItem(THEME_MODE),
  language: 'EN',
  sidebar: true,
};

const triggersReducer = createReducer(initialState, (builder) => {
  builder
    .addCase(setLanguage, (state, action) => {
      state.language = action.payload;
    })
    .addCase(toggleTheme, (state) => {
      state.darkMode = !state.darkMode;
    })
    .addCase(hideSidebar, (state) => {
      state.sidebar = false;
    })
    .addCase(showSidebar, (state) => {
      state.sidebar = true;
    })
    .addCase(toggleSidebar, (state, action) => {
      state.sidebar = action.payload;
    });
});

export default triggersReducer;
