import { NotificationManager } from 'react-notifications';
import axios from 'axios';
import { IQueryParams } from 'src/types';

import { IPaymentMethodsData } from './types';

const PAYMENT_METHODS_URL = 'v1/backoffice/p2p/paymentMethod/list';

export const fetchPaymentMethodsRequest = async (params: IQueryParams = {}): Promise<IPaymentMethodsData> => {
  try {
    const res = await axios.get(PAYMENT_METHODS_URL, { params });

    return res.data;
  } catch (error) {
    NotificationManager.error("Can't fetch payment methods");

    return {
      items: [],
    };
  }
};
