export class UrlBuilder {
  constructor(private baseRoute: string) {}

  build = (...paths: string[]): string => {
    const base = `/${this.baseRoute}`;

    if (paths?.length) {
      return `${base}/${(paths || []).join('/')}`;
    } else {
      return base;
    }
  };
}
