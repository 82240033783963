import { createReducer } from '@reduxjs/toolkit';

import { setLoading, setPaymentMethodsData } from './actions';
import { IPaymentMethodsData } from './types';

interface IPaymentMethodsState {
  isLoading: boolean;
  data: IPaymentMethodsData;
}

const initialState: IPaymentMethodsState = {
  isLoading: true,
  data: {},
};

const paymentMethodsReducer = createReducer(initialState, (builder) => {
  builder
    .addCase(setLoading, (state, action) => {
      state.isLoading = action.payload;
    })
    .addCase(setPaymentMethodsData, (state, action) => {
      state.data = action.payload;
    });
});

export default paymentMethodsReducer;
