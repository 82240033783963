import axios from 'axios';
import { sha256 } from 'js-sha256';
import { IFlowData } from 'src/components/Form/types';
import { API_KEY, AUTH_COOKIE_NAME } from 'src/constants';
import Cookies from 'universal-cookie';

const cookies = new Cookies();

export const setJWTCookie = (token: string): void => {
  cookies.set(AUTH_COOKIE_NAME, token, {
    path: '/',
    expires: new Date(Date.now() + 7 * 24 * 3600 * 1000), // 1 неделя
  });

  setAuthToken(token);
};

export const removeJWTCookie = (): void => {
  cookies.remove(AUTH_COOKIE_NAME, { path: '/' });

  delete axios.defaults.headers.common.Authorization;
};

export const setAuthToken = (token: string): void => {
  axios.defaults.headers.common['X-Auth-Token'] = token;
};

export const createApiSign = (body: IFlowData): string => {
  //Sort properties alphabetically
  const sortBody = Object.keys(body)
    .sort()
    .reduce<IFlowData>((obj, key) => {
      // eslint-disable-next-line no-param-reassign
      obj[key] = body[key];
      return obj;
    }, {});

  const stringToHex = JSON.stringify(sortBody) + '\n' + process.env.REACT_APP_API_HOST + '\n' + API_KEY;

  return sha256.hex(stringToHex);
};
