import { createReducer } from '@reduxjs/toolkit';

import { setUserToken } from './actions';

const initialState = {
  token: '',
  permissions: [] as string[],
};

const authReducer = createReducer(initialState, (builder) => {
  builder.addCase(setUserToken, (state, action) => ({
    ...state,
    ...action.payload,
  }));
});

export default authReducer;
