import React, { FC, ReactNode, Suspense, useEffect } from 'react';
import { BrowserRouter, Route, Switch } from 'react-router-dom';
import { useAppDispatch, useAppSelector } from 'src/hooks';
import { checkCookie, selectUserToken } from 'src/store/api/auth';
import { CSpinner } from '@coreui/react-pro';

import './scss/style.scss';
import 'react-notifications/lib/notifications.css';

import './axiosConfig';

const PrivateLayout = React.lazy(() => import('src/layout/PrivateLayout'));
const PublicLayout = React.lazy(() => import('src/layout/PublicLayout'));
const Login = React.lazy(() => import('src/pages/Login/Login'));

const App: FC<ReactNode> = () => {
  const dispatch = useAppDispatch();

  const userToken = useAppSelector(selectUserToken);

  useEffect(() => {
    dispatch(checkCookie());
  }, [dispatch]);

  return userToken ? (
    <BrowserRouter>
      <Suspense fallback={<CSpinner color="primary" variant="grow" />}>
        <Switch>
          <Route path="/" render={() => <PrivateLayout />} />
        </Switch>
      </Suspense>
    </BrowserRouter>
  ) : (
    <Suspense fallback={<CSpinner color="primary" variant="grow" />}>
      <PublicLayout>
        <Login />
      </PublicLayout>
    </Suspense>
  );
};

export default App;
