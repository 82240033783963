import { AppDispatch } from 'src/store';
import { createAction } from '@reduxjs/toolkit';

import { fetchApplicationRequest, fetchUserRequest, updateApplicationRequest, updateUserRequest } from './requestHandlers';
import { Application, User } from './types';

export const setLoading = createAction<boolean>('settings/loading/set');
export const setSending = createAction<boolean>('settings/sending/set');
export const setUserData = createAction<User | null>('settings/user/setData');
export const setApplicationData = createAction<Application | null>('settings/application/setData');

export const fetchUser = () => {
  return async (dispatch: AppDispatch): Promise<void> => {
    dispatch(setLoading(true));

    const user = await fetchUserRequest();
    dispatch(setUserData(user));
    dispatch(setLoading(false));
  };
};

export const updateUser = (data: User) => {
  return async (dispatch: AppDispatch): Promise<void> => {
    dispatch(setSending(true));

    const user = await updateUserRequest(data);
    if (user) dispatch(setUserData(user));

    dispatch(setSending(false));
  };
};

export const fetchApplication = () => {
  return async (dispatch: AppDispatch): Promise<void> => {
    dispatch(setLoading(true));

    const application = await fetchApplicationRequest();
    dispatch(setApplicationData(application));
    dispatch(setLoading(false));
  };
};

export const updateApplication = (data: Application) => {
  return async (dispatch: AppDispatch): Promise<void> => {
    dispatch(setSending(true));

    const application = await updateApplicationRequest(data);
    if (application) dispatch(setApplicationData(application));

    dispatch(setSending(false));
  };
};
