import { RouteComponentProps } from 'react-router-dom';
import { IQueryParams } from 'src/types';

export enum EP2pRuTransactionStatus {
  NOT_INITIATED = 'NOT_INITIATED',
  INIT = 'INIT',
  PENDING = 'PENDING',
  UNPAID = 'UNPAID',
  PAID = 'PAID',
  MISMATCH = 'MISMATCH',
  DISPUTE = 'DISPUTE',
  COMPLETED = 'COMPLETED',
  DECLINED = 'DECLINED',
  CANCELED = 'CANCELED',
  EXPIRED = 'EXPIRED',
  TEMPORARY_EXPIRED = 'TEMPORARY_EXPIRED',
}

export interface IP2pRuTransaction {
  uuid: string;
  agentName: string;
  clientId: string;
  currencyCode: string;
  amount: number;
  amountToEnroll: number;
  cardNumber: string;
  status: EP2pRuTransactionStatus;
  createdAt: string;
  updatedAt: string;
}

export interface IP2pRuTransactionsTableData {
  items: IP2pRuTransaction[];
  total?: number;
  offset?: number;
  limit?: number;
}

export interface IP2pRuTransactionsFilters {
  statuses?: EP2pRuTransactionStatus[];
  clientId?: string;
  currencyCodes?: string[];
  from?: string;
  to?: string;
}

export interface IFetchP2pRuTransactions {
  history: RouteComponentProps['history'];
  params?: IQueryParams;
  isReset?: boolean;
}

export interface IFetchP2pRuTransactionsRequest {
  params: IQueryParams;
}

export enum EP2pRuTransactionsActionsTypes {
  SetIsLoading = 'p2p-ru-transactions/loading/set',
  SetIsSending = 'p2p-ru-transactions/sending/set',
  SetTable = 'p2p-ru-transactions/table/set',
}
