import qs from 'query-string';
import { DEFAULT_PARAMS } from 'src/constants';
import { AppDispatch } from 'src/store';
import { removeEmpty } from 'src/utils/removeEmpty';
import { createAction } from '@reduxjs/toolkit';

import { fetchTransactionsRequest, getTransactionScreensRequest, updateTransactionStatusRequest } from './requestHandlers';
import { IFetchTransactions, IScreen, ITransactionsData, ITransactionStatusUpdate } from './types';

export const setLoading = createAction<boolean>('transactions/loading/set');
export const setSending = createAction<boolean>('transactions/sending/set');
export const setTransactionsData = createAction<ITransactionsData>('transactions/data/load');
export const setTransactionStatus = createAction<ITransactionStatusUpdate>('transactions/status/set');
export const setTransactionScreens = createAction<IScreen[] | null>('transactions/screen/set');
export const setTransactionScreensRefresh = createAction('transactions/screen/refresh');

export const fetchTransactions = ({ history, params = {}, isReset, isRequestsPage }: IFetchTransactions) => {
  return async (dispatch: AppDispatch): Promise<void> => {
    dispatch(setLoading(true));

    const initialParams = qs.parse(history.location.search);

    const mergedParams = isReset ? { ...DEFAULT_PARAMS } : { ...DEFAULT_PARAMS, ...removeEmpty({ ...initialParams, ...params }) };

    const transactions = await fetchTransactionsRequest({ params: mergedParams, isRequestsPage });

    dispatch(setTransactionsData(transactions));

    history.replace({
      search: qs.stringify({ offset: mergedParams.offset, limit: mergedParams.limit }, { skipNull: true }),
    });

    dispatch(setLoading(false));
  };
};

export const updateTransactionStatus = (data: ITransactionStatusUpdate) => {
  return async (dispatch: AppDispatch): Promise<boolean> => {
    dispatch(setSending(true));

    const result = await updateTransactionStatusRequest(data);
    dispatch(setSending(false));

    if (result) {
      dispatch(setTransactionStatus(data));
      return true;
    }
    return false;
  };
};

export const featchTransactionScreens = (id: number) => {
  return async (dispatch: AppDispatch): Promise<void> => {
    const result = await getTransactionScreensRequest(id);
    dispatch(setTransactionScreens(result));
  };
};
