import { NotificationManager } from 'react-notifications';
import { AUTH_COOKIE_NAME } from 'src/constants';
import { AppDispatch } from 'src/store';
import { removeJWTCookie, setAuthToken, setJWTCookie } from 'src/utils/auth';
import Cookies from 'universal-cookie';
import { createAction } from '@reduxjs/toolkit';

import { fetchToken } from './requestHandlers';
import { IAuth, ILoginForm } from './types';
const cookies = new Cookies();

export const setUserToken = createAction<IAuth>('auth/set');

export function loginUser(data: ILoginForm) {
  return async (dispatch: AppDispatch): Promise<void> => {
    try {
      const { token } = await fetchToken(data);

      if (token) {
        setJWTCookie(token);
        dispatch(setUserToken({ token }));
      }
    } catch {
      NotificationManager.error("Can't login");
    }
  };
}

export function checkCookie() {
  return async (dispatch: AppDispatch): Promise<void> => {
    const token = cookies.get(AUTH_COOKIE_NAME);

    if (token) {
      try {
        dispatch(setUserToken({ token }));
        setAuthToken(token);
      } catch {
        logoutUser();
      }
    }
  };
}

export function logoutUser() {
  return async (dispatch: AppDispatch): Promise<void> => {
    removeJWTCookie();
    dispatch(setUserToken({ token: '' }));
  };
}
