import { createReducer } from '@reduxjs/toolkit';

import {
  setLoading,
  setSending,
  setTransactionScreens,
  setTransactionScreensRefresh,
  setTransactionsData,
  setTransactionStatus,
} from './actions';
import { IScreen, ITransactionsData } from './types';

interface ITransactionsState {
  isLoading: boolean;
  isSending: boolean;
  data: ITransactionsData;
  screens: IScreen[] | null;
}

const initialState: ITransactionsState = {
  isLoading: true,
  isSending: false,
  data: {
    items: [],
  },
  screens: null,
};

const transactionsReducer = createReducer(initialState, (builder) => {
  builder
    .addCase(setLoading, (state, action) => {
      state.isLoading = action.payload;
    })
    .addCase(setTransactionsData, (state, action) => {
      state.data = action.payload;
    })
    .addCase(setSending, (state, action) => {
      state.isSending = action.payload;
    })
    .addCase(setTransactionStatus, (state, action) => ({
      ...state,
      data: {
        ...state.data,
        items: state.data.items.map((el) => {
          const current = { ...el };

          if (current.id === action.payload.id) {
            current.status = action.payload.body.status;
          }

          return current;
        }),
      },
    }))
    .addCase(setTransactionScreens, (state, action) => {
      state.screens = action.payload;
    })
    .addCase(setTransactionScreensRefresh, (state) => {
      state.screens = null;
    });
});

export default transactionsReducer;
