import { createReducer } from '@reduxjs/toolkit';

import { p2pRuTransactionsActions } from './actions';
import { IP2pRuTransactionsTableData } from './types';

interface IP2pRuTransactionsState {
  isLoading: boolean;
  isSending: boolean;
  data: IP2pRuTransactionsTableData;
  screens: string[] | null;
}

const initialState: IP2pRuTransactionsState = {
  isLoading: true,
  isSending: false,
  data: {
    items: [],
  },
  screens: null,
};

export default createReducer(initialState, (builder) => {
  builder
    .addCase(p2pRuTransactionsActions.setLoading, (state, action) => {
      state.isLoading = action.payload;
    })
    .addCase(p2pRuTransactionsActions.setTransactionsTable, (state, action) => {
      state.data = action.payload;
    })
    .addCase(p2pRuTransactionsActions.setSending, (state, action) => {
      state.isSending = action.payload;
    });
});
