import { RouteComponentProps } from 'react-router-dom';
import qs from 'query-string';
import { ISelectOptions } from 'src/components/Select/types';
import { DEFAULT_PARAMS } from 'src/constants';
import { AppDispatch } from 'src/store';
import { IQueryParams } from 'src/types';
import { removeEmpty } from 'src/utils/removeEmpty';
import { createAction } from '@reduxjs/toolkit';

import { fetchPaymentMethodsRequest } from '../paymentMethods/requestHandlers';

import { createPaymentAccountRequest, fetchPaymentAccountsRequest, updatePaymentAccountRequest } from './requestHandlers';
import { IPaymentAccount, IPaymentAccountParams, IPaymentAccountsData } from './types';

export const setLoading = createAction<boolean>('paymentAccounts/loading/set');
export const setSending = createAction<boolean>('paymentAccounts/sending/set');
export const setPaymentAccountsData = createAction<IPaymentAccountsData>('paymentAccounts/data/load');
export const setPaymentAccount = createAction<IPaymentAccount>('paymentAccounts/data/update');

export const fetchPaymentAccounts = (history: RouteComponentProps['history'], params: IQueryParams = {}, isReset?: boolean) => {
  return async (dispatch: AppDispatch): Promise<void> => {
    dispatch(setLoading(true));

    const initialParams = qs.parse(history.location.search);

    const mergedParams = isReset ? DEFAULT_PARAMS : { ...DEFAULT_PARAMS, ...removeEmpty({ ...initialParams, ...params }) };

    const paymentAccounts = await fetchPaymentAccountsRequest(mergedParams);

    dispatch(setPaymentAccountsData(paymentAccounts));

    history.replace({
      search: qs.stringify(mergedParams, { skipNull: true }),
    });

    dispatch(setLoading(false));
  };
};

export const updatePaymentAccount = async (data: IPaymentAccountParams): Promise<IPaymentAccount | undefined> =>
  updatePaymentAccountRequest(data);

export const createPaymentAccount = async (data: IPaymentAccountParams): Promise<IPaymentAccount | void> =>
  createPaymentAccountRequest(data);

export const fetchPaymentMethods = async (): Promise<ISelectOptions> => {
  const { items } = await fetchPaymentMethodsRequest();

  return items?.length
    ? items.map((el) => {
        return {
          value: el.method,
          label: el.method,
        };
      })
    : [];
};
