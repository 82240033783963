import { DEFAULT_PARAMS } from 'src/constants';
import { AppDispatch } from 'src/store';
import { createAction } from '@reduxjs/toolkit';

import { fetchPaymentMethodsRequest } from './requestHandlers';
import { IPaymentMethodsData } from './types';

export const setLoading = createAction<boolean, 'paymentMethods/loading/set'>('paymentMethods/loading/set');
export const setPaymentMethodsData = createAction<IPaymentMethodsData, 'paymentMethods/data/load'>('paymentMethods/data/load');

export const fetchPaymentMethods = () => {
  return async (dispatch: AppDispatch): Promise<void> => {
    dispatch(setLoading(true));

    const paymentMethods = await fetchPaymentMethodsRequest(DEFAULT_PARAMS);

    dispatch(setPaymentMethodsData(paymentMethods));
    dispatch(setLoading(false));
  };
};
