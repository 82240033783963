import { NotificationManager } from 'react-notifications';
import axios from 'axios';
import { IQueryParams } from 'src/types';
import { UrlBuilder } from 'src/utils/UrlBuilder';

import { IFetchP2pRuTransactionsRequest, IP2pRuTransactionsTableData } from './types';

const urlBuilder = new UrlBuilder('v1/backoffice/p2p-ru/transactions');

const fetchTransactionsRequest = async ({ params }: IFetchP2pRuTransactionsRequest): Promise<IP2pRuTransactionsTableData> => {
  const { offset = 0, limit = 10, ...otherParams } = params || {};
  const page = Math.ceil((offset - 1) / parseInt(limit));

  try {
    const { data } = await axios.get(urlBuilder.build(), {
      params: {
        ...otherParams,
        page: page,
        limit,
        orderingType: 'DESC',
      },
    });
    return data;
  } catch (error) {
    NotificationManager.error("Can't fetch transactions");
    return {
      items: [],
    };
  }
};

const declineDispute = async (uuid: string): Promise<void> => {
  await axios.post(urlBuilder.build(uuid, 'dispute-decline'));
};

const completeDispute = async (uuid: string, amountToEnroll: number): Promise<void> => {
  await axios.post(urlBuilder.build(uuid, 'dispute-complete'), null, { params: { amountToEnroll } });
};

const declineExpiration = async (uuid: string): Promise<void> => {
  await axios.post(urlBuilder.build(uuid, 'expired-decline'));
};

const completeExpiration = async (uuid: string, amountToEnroll: number): Promise<void> => {
  await axios.post(urlBuilder.build(uuid, 'expired-complete'), null, { params: { amountToEnroll } });
};

export const getScreens = async (uuid: string): Promise<string[]> => {
  const { data } = await axios.get(urlBuilder.build(uuid, 'screenshots'));
  return data.screenshots;
};

export const getDownloadCsvHref = async (params: IQueryParams): Promise<string> => {
  const { data } = await axios.get(urlBuilder.build('csv'), {
    responseType: 'blob',
    params: {
      ...params,
      orderingType: 'DESC',
    },
  });

  return URL.createObjectURL(data);
};

export const ruTransactionsApi = {
  declineDispute,
  fetchTransactionsRequest,
  completeDispute,
  declineExpiration,
  completeExpiration,
  getScreens,
  getDownloadCsvHref,
};
