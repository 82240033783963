import { AxiosError, AxiosResponse } from 'axios';

import { IBackError } from '../types/Error';

const isJsonBlob = (response: AxiosResponse) => response.data instanceof Blob && response.data.type === 'application/json';

export const readBlobErrorData = async <T extends Blob>(error: AxiosError<T>): Promise<IBackError> => {
  // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
  const responseData = isJsonBlob(error.response!) ? await error.response?.data?.text() : error.response?.data || {};
  return typeof responseData === 'string' ? JSON.parse(responseData) : responseData;
};
