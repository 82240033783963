import { RootState } from 'src/store';

import { IScreen, ITransactionsData } from './types';

export const selectIsLoadingTransactions = (state: RootState): boolean => state.transactions.isLoading;

export const selectIsSending = (state: RootState): boolean => state.transactions.isSending;

export const selectTransactions = (state: RootState): ITransactionsData => state.transactions.data;
export const selectTransactionScreens = (state: RootState): IScreen[] | null => state.transactions.screens;
