import { NotificationManager } from 'react-notifications';
import axios from 'axios';

import { ILoginForm, IToken } from './types';

export async function fetchToken(data: ILoginForm): Promise<IToken> {
  try {
    const res = await axios.post(`/v1/backoffice/p2p/auth`, data);

    return res.data;
  } catch (error) {
    NotificationManager.error('Auth failed');
    return { token: '' };
  }
}
