import { createReducer } from '@reduxjs/toolkit';

import { setLoading, setPaymentAccount, setPaymentAccountsData } from './actions';
import { IPaymentAccountsData } from './types';

interface IPaymentAccountsState {
  isLoading: boolean;
  isSending: boolean;
  data: IPaymentAccountsData;
}

const initialState: IPaymentAccountsState = {
  isLoading: true,
  isSending: false,
  data: {
    items: [],
  },
};

const paymentAccountsReducer = createReducer(initialState, (builder) => {
  builder
    .addCase(setLoading, (state, action) => {
      state.isLoading = action.payload;
    })
    .addCase(setPaymentAccountsData, (state, action) => {
      state.data = action.payload;
    })
    .addCase(setPaymentAccount, (state, action) => {
      state.data.items.forEach((item, index) => {
        if (item.id === action.payload.id) {
          state.data.items[index] = action.payload;
        }
      });
    });
});

export default paymentAccountsReducer;
