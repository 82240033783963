import { NotificationManager } from 'react-notifications';
import axios from 'axios';
import { IQueryParams } from 'src/types';

import { IPaymentAccount, IPaymentAccountParams, IPaymentAccountsData } from './types';

const PAYMENT_ACCOUNTS_URL = 'v1/backoffice/p2p/managedPaymentAccount';

export const fetchPaymentAccountsRequest = async (params: IQueryParams): Promise<IPaymentAccountsData> => {
  try {
    const res = await axios.get(`${PAYMENT_ACCOUNTS_URL}/list`, { params });

    return res.data;
  } catch (error) {
    NotificationManager.error("Can't fetch payment accounts");

    return {
      items: [],
    };
  }
};

export const fetchPaymentAccountByIdRequest = async (id: string): Promise<IPaymentAccount | void> => {
  try {
    const res = await axios.get(`${PAYMENT_ACCOUNTS_URL}/${id}/info`);

    return res.data;
  } catch (error) {
    NotificationManager.error("Can't fetch a payment account");
  }
};

export const createPaymentAccountRequest = async (data: IPaymentAccountParams): Promise<IPaymentAccount | void> => {
  try {
    const res = await axios.post(`${PAYMENT_ACCOUNTS_URL}/create`, data.body);

    NotificationManager.success('A payment account successfully created');

    return res.data;
  } catch (error) {
    NotificationManager.error("Can't create a payment account");
  }
};

export const updatePaymentAccountRequest = async (data: IPaymentAccountParams): Promise<IPaymentAccount | undefined> => {
  try {
    const res = await axios.post(`${PAYMENT_ACCOUNTS_URL}/${data.id}/update`, data.body);

    NotificationManager.success('A Payment account successfully updated');

    return res.data;
  } catch (error) {
    NotificationManager.error("Can't update a payment account");
  }
};
